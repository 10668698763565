import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import axios from 'axios';

const Home = () => <h2>Home Page</h2>;

const Data = () => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    axios.get('http://localhost/api/data.php')
      .then(response => setData(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>
      <h2>Data from API:</h2>
      <ul>
        {data.length > 0 ? (
          data.map(item => (
            <li key={item.id}>{item.name}</li>
          ))
        ) : (
          <li>No data available</li>
        )}
      </ul>
    </div>
  );
};

const App = () => {
  return (
    <div>
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/data">Data</Link></li>
        </ul>
      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/data" element={<Data />} />
      </Routes>
    </div>
  );
};

export default App;
